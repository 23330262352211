import VueKeycloakJs from '@dsb-norge/vue-keycloak-js'
import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

Vue.use(VueKeycloakJs, {
  init: {
    // Use 'login-required' to always require authentication
    // If using 'login-required', there is no need for the router guards in router.js
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
  },
  config: {
    url: 'https://kc21.esclouddev.com',
    clientId: 'vue-client-s3-3',
    realm: 'master',
  },
})

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')

/*
https://kc21.esclouddev.com/realms/master/protocol/openid-connect/auth?client_id=security-admin-console&redirect_uri=https%3A%2F%2Fkc21.esclouddev.com%2Fadmin%2Fmaster%2Fconsole%2F&state=9b6ccb7a-ef14-498f-9500-cc985336505b&response_mode=fragment&response_type=code&scope=openid&nonce=544e8003-7bff-4e79-9297-2ae0cadbcddf&code_challenge=lHoebZ9WkRzSfACOGSw04TaieDv1Okcm3Ds91M36xTM&code_challenge_method=S256
https://kc21.esclouddev.com/auth/realms/master/protocol/openid-connect/auth?client_id=vue-client-s3-3&redirect_uri=https%3A%2F%2Fkeycloak-vuetest3.esclouddev.com%2F&state=f7296bf5-ad06-48cd-8b46-7c6793e608ab&response_mode=fragment&response_type=code&scope=openid&nonce=8d1a7220-f9b0-4a66-b37a-1f8242fa7112
https://kc19.esclouddev.link/auth/realms/master/protocol/openid-connect/auth?client_id=vue-client-s3-2&redirect_uri=http%3A%2F%2Fkeycloak-vuetest2.esclouddev.com%2F&state=7fe0d740-07a0-41ed-89bd-50e5aba116c8&response_mode=fragment&response_type=code&scope=openid&nonce=77881f23-b333-4cfa-b380-6f5acb132cb0
*/
